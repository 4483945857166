import { close as folksSelectorClose } from './folks-selector.js';
import { updateSearchBarOptions } from "./search-bar.js"
import { submit as dynamicSearchSubmit } from './dynamic-search.js';
import { breakpoints } from "./utilities.js";

export default function() {

	$('.m-tabs.js-internal:not(.js-tabs-done)').each(function() {
		var wrapper = $(this);
		var allow = true;
		var title = wrapper.find('.js-tabs-title');
		var linksGroup = wrapper.find('.js-tabs-links');
		var links = linksGroup.find('.js-tabs-links-link');
		var tabsGroup = wrapper.find('.js-tabs-tab');
		var searchBlock = wrapper.is('.js-tabs-search-block') ? true : false;
		var clicks = 0;

		// hide tabs
		tabsGroup.hide();

		// title click
		title.off('click').on('click', function() {
			if (!allow) return false;
			allow = false;
			// toggle nav
			linksGroup.slideToggle(250, function() {
				// toggle class
				title.toggleClass('active');
				// allow
				allow = true;
			});
			return false;
		});

		// links click
		links.off('click').on('click', function() {
			var link = $(this);
			var tab = tabsGroup.filter(link.attr('href'));
			var tabAlreadyActive = link.hasClass('active');
			if (!allow || !tab.length) return false;
			allow = false;
			// toggle class
			links.removeClass('active');
			link.addClass('active');
			// hide tabs
			tabsGroup.hide();
			// show tab
			tab.show();
			//map resize
			window.site.win.resize();
			// set title
			title.html(link.html());
			// collapse
			if (window.matchMedia('(max-width: ' + (breakpoints.desktop - 1) + 'px)').matches) {
				linksGroup.slideUp(250, function() {
					// set title
					title.removeClass('active');
					// allow
					allow = true;
				});
			}
			else {
				// allow
				allow = true;
			}

			if (searchBlock) {

				tabsGroup.find('input:not(.js-no-disable), select, button').attr('disabled', 'disabled');
				tabsGroup.find('.select--faux').removeClass('js-folks-selector-trigger');

				if (!tab.is('.js-tabs-tab-disabled')) {
					tab.find('input, select, button').not('.js-disabled-input').removeAttr('disabled');
					tab.find('.select--faux').addClass('js-folks-selector-trigger');
				}

				window.site.body.trigger('closeDatePicker');

				// dynamic search

				if (clicks > 0 && wrapper.parents('.js-dynamic-search').length && !tabAlreadyActive) {
					// Wait for search options to update before submitting the search
					window.site.doc.one("searchOptionsReceived", function(data) {
						dynamicSearchSubmit(false);
					});
				}

				if (!tabAlreadyActive) {
					updateSearchBarOptions();
					folksSelectorClose($('.js-folks-selector-popup-inputs-wrapper:visible').closest('.js-folks-selector-wrapper'), true);
				}
			}

			clicks++;

			return false;
		});

		// set active
		if (links.filter('.active').length) {
			links.filter('.active').click();
		}
		else {
			links.first().click();
		}

		// done
		wrapper.addClass('js-tabs-done');
	});

}
